import React from 'react';
import { Button } from 'react-bootstrap';

import './TileButton.scss';

interface ITileButton {
  value: any;
  children: any;
  onClick: Function;
  className?: string;
  variant?: string;
}

const TileButton : React.FC<ITileButton> = (props: ITileButton) => {
  const onClick = (e: any) => {
    props.onClick(e);
  };

  return (
    <Button onClick={() => onClick(props.value)} className="gerx-tile-button" variant={props.variant}>{props.children}</Button>
  );
};

export default TileButton;