import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import LoadingOverlay from 'react-loading-overlay';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useIsAuthenticated } from '@azure/msal-react';

import IAuthProps from '../../Containers/Auth/IAuthProps';
import Menubar from '../Layout/Menubar/Menubar';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import Home from '../Pages/Home';
import About from '../Pages/About';
import Contact from '../Pages/Contact';
import News from '../Pages/News';
import Statistics from '../Pages/Statistics/Statistics';
import Logout from '../Pages/Logout';
import Version from '../Pages/Version';
import NotFound from '../Pages/NotFound';
import Projects from '../Projects/Projects';
import Users from '../Users/Users';
import TestRBS from '../Pages/TestRBS';
import ErrorMessage from '../Auth/ErrorMessage';
import Landing from '../Pages/Landing';
import UserProfile from '../Pages/UserProfile';
import GetDate from '../LandPortalApi/GetDate';
import Privacy from '../Pages/Privacy';
import TermsAndConditions from '../Pages/TermsAndConditions';
import { loaderStyle } from '../../Helpers/constants';
import { selectIsLoading } from '../../Store/Selectors/rootSelector';
import { getUserProfile, startLoading, stopLoading } from '../../Store/Reducers/GerxStoreSlice';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './App.scss';
import SignIn from '../Auth/SignIn';
import Signout from '../Auth/Signout';

const tagManagerArgs = {
  gtmId: 'GTM-P6XZX5W'
}

TagManager.initialize(tagManagerArgs);

const App = (props: IAuthProps) => {
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const isLoading = useSelector(selectIsLoading);

  const getData = async () => {
    try {
      await dispatch(getUserProfile(props.getAccessToken));
      return true;
    }
    catch (err) {
      if (err.message.search('401') != -1) {
        return false;
      }
    }
  };

  useEffect(() => {
    const refreshObj = async () => {
      if(!isAuthenticated && window.location.href.search('#state=') > -1) {
        await props.login();
      }

      if (isAuthenticated) {
        dispatch(startLoading());
        let retVal = await getData();
        if (!retVal) {
          let count= 0;
          const interval = await setInterval(async () => {
            retVal = await getData();
            count++;
            if (retVal || (count === 5)) {
              clearTimeout(interval);
            }
            if (retVal) {
              dispatch(stopLoading());
            }
          }, 2000);
        }
        if (retVal) dispatch(stopLoading());
      }
    };

    refreshObj();
  }, [isAuthenticated])
  
  const signout = (origin: string) => {

    props.logout();

    // if (!origin) {
    //   window.location.href = process.env.REACT_APP_GERX_LOGOUT_REDIRECT_LAST_APP_URL + '/signout?origin=gerx' || '/';
    // }
    // else {
    //   props.logout();
    //   //window.location.href = '/';
    // }
    // // let url = '';
    // // switch (origin) {
    // //   case '': url = process.env.REACT_APP_LANDTRACK_LOGOUT_URI || ''; break;
    // //   case 'landtrack': url = process.env.REACT_APP_LANDPORTAL_LOGOUT_URI || ''; break;
    // //   case 'landportal': url = ''; break;
    // // }

    // // if (url) {
    // //   window.location.href = url + '/signout';
    // // }
    // // else {
    // //   props.logout();
    // // }
  }

  let error = null;
  if (props.error) {
    error = <ErrorMessage
      message={props.error.message}
      debug={props.error.debug} />;
  }

  return (
    <>
      <Router>
      <Route path="/signout" render={(props2) => {
        const origin = props2.location.search.split('?origin=')[1] || '';
        //signout(origin);
        //return <></>;
        if (!origin) {
          setTimeout(() =>{
            props.logout();
          }, 500);            
        }
        else {
          return <Signout origin={origin} />;
        }

      return <>Please wait while we sign you out from GERX</>
      }} />
      </Router>
      {
        window.location.href.search("/signout") > -1 ? <></> :
        <Router>
          <Header/>
            <div className="App">
              <LoadingOverlay active={isLoading} spinner text='Loading Profile - Please Wait...' styles={loaderStyle}>              
                <Menubar
                  isAuthenticated={isAuthenticated}
                  authButtonMethod={isAuthenticated ? props.logout : props.login}
                  signUpButtonMethod={props.signup}
                  signOutButtonMethod={props.logout}
                  user={props.user} />
                
                <Container>
                  {error}
                  <Switch>
                    {/* <Route path="/signin" exact render={() => {
                      return <SignIn login={props.login}></SignIn>
                    }} /> */}

                    <Route path="/signin" exact component={SignIn} />

                    {/* <Route path="/signout" render={(props2) => {
                        const origin = props2.location.search.split('?origin=')[1] || '';
                        //signout(origin);
                        //return <></>;
                        if (!origin) {
                          props.logout();
                        }
                        else {
                          return <Signout origin={origin} />;
                        }
                      }} /> */}

                    <Route path="/" exact render={() =>
                      {
                        return (
                          <>
                            {isAuthenticated ? <Landing /> :
                              <Home {...props}
                                isAuthenticated={isAuthenticated}
                                user={props.user}
                                authButtonMethod={props.login} />
                            }
                          </>
                        );
                      }
                    } />

                    {/* 
                    <Route path="/home" render={(props) => (
                        <Home {...props}
                          isAuthenticated={props.isAuthenticated}
                          user={props.user}
                          authButtonMethod={props.login} />
                      )
                    } /> */}

                    <Route path="/about" component={About} />
                    <Route path="/contact" component={Contact} />
                    <Route path="/news" component={News} />
                    <Route path="/statistics" component={Statistics} />
                    <Route path="/version" component={Version} />
                    <Route path="/logout" component={Logout} />
                    <Route path="/privacy" component={Privacy} />
                    <Route path="/terms" component={TermsAndConditions} />
                    <Route path="/userprofile" component={UserProfile} />
                    
                    <Route path="/userprofile" render={(props2) => (
                        <UserProfile {...props2}
                        isAuthenticated={isAuthenticated}
                        user={props.user}
                        authButtonMethod={props.login} />
                      )
                    } />

                    <Route path="/callapi" render={(props2) => (
                        <GetDate {...props2}
                          isAuthenticated={isAuthenticated}
                          user={props.user}
                          authButtonMethod={props.login} 
                          getAccessToken={null}
                          getTokens={props.getTokens}/>
                      )
                    } />

                    <Route path="/test" component={TestRBS} />
                    <Route path="/projects/:projectId" component={Projects} />
                    <Route path="/users/:userId" component={Users} />
                    <Route default component={NotFound} />

                </Switch>
                </Container>
              </LoadingOverlay>
            </div>
          <Footer/>
        </Router>
      }      
    </>
    );
}

export default App;
