import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

interface Props { }

interface State {
  isOpen: boolean //KS: Can define any specific state params
}

class TermsAndConditions extends React.Component<Props, State> {

  constructor(props: Props, state: State) {
    super(props);

    //KS: Set default state params as required
    this.state = {
      isOpen: false
    };
  }

  render() {
    return (
      <>
        <div className="App-TermsAndConditions">
          <h4>Terms And Conditions</h4>
          <p>Greening Australia Limited and its subsidiaries are committed to providing quality services to you and we do so under the terms and conditions described in this document.</p>
          <p>
          The Terms refer, collectively, to all the terms, conditions, notices contained or referenced in this document (the “Terms of Service” or the "Terms") and all other operating rules, policies (including the Privacy Statement, available at https://landholder.gerx.org.au/privacy) and procedures that we may publish from time to time. 
          </p>
          <p>
            Specific items to remember to add:
            <ul>
              <li>Specifically not allowed to use computer security tools to attempt hack</li>
              <li>Failure to comply with Ts &amp; Cs may result in termination of your account</li>
            </ul>
          </p>
          <p>If you have any queries or complaints about our Terms and Conditions please contact us at:</p>
          <p><strong>Greening Australia Limited</strong></p>
          <p><em>Company Secretary</em></p>
          <p>Level 3, 349 Collins St</p>
          <p>Melbourne VIC 3000</p>
          <p>Email: <a href="mailto:communications@greeningaustralia.org.au"><span>communications@greeningaustralia.org.au</span></a></p>
          <p>Phone: 03 9450 5323</p>
          <p><strong>Dated: 16<sup>st</sup> November 2020</strong></p>
        </div>
      </>
    );
  }
}

export default TermsAndConditions;
