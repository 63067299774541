import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

interface Props {
  location: any;
  match: any
}

interface State {
  isOpen: boolean //KS: Can define any specific state params
}

class Projects extends React.Component<Props, State> {

  constructor(props: Props, state: State) {
    super(props);

    //KS: Set default state params as required
    this.state = {
      isOpen: false
    };
  }

  render() {
    console.log("Got Params as", this.props);
    const {match} = this.props;
    return (
        <div>
            This is a Projects page.
            {match.isExact}
        </div>
    );
  }
}

export default Projects;
