import axios from 'axios';
import { IUserProfile } from '../Models/Auth/IUser';
import { getConfig } from '../Services/serviceHelper';

class GerxApiService {

  baseUrl: string | undefined;
  baseUrlLandportal: string | undefined;
  baseUrlLandcare: string | undefined;
  baseModuleName: string | undefined;
  containerName: string | undefined;

  constructor () {
    this.baseUrl = process.env.REACT_APP_API_BASEURI;
    this.baseUrlLandcare = process.env.REACT_APP_LANDCARE_BASEURI;
    this.baseUrlLandportal = process.env.REACT_APP_LANDPORTAL_BASEURI;
  }

  fetchUserProfile = async (accessToken: string) : Promise<IUserProfile> => {
    const url = `${this.baseUrl}userprofile`;
    try {
      const response = await axios.get(url, getConfig(accessToken));
      return response.data.user[0];
    }
    catch (err: any) {
      throw (err)
    }
  }
};

export default GerxApiService;