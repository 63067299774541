import { configureStore, getDefaultMiddleware, combineReducers, Action }  from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

import { GerxStoreReducers } from './Reducers/GerxStoreSlice';

const RootStore = configureStore({
  reducer: combineReducers({
    gerxStore: GerxStoreReducers
  }),
  middleware: getDefaultMiddleware(),
  devTools: true
});

export default RootStore;

export type RootState = ReturnType<typeof RootStore.getState>;
export type AppDispatch = typeof RootStore.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;