import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

interface Props { }

interface State {
  isOpen: boolean //KS: Can define any specific state params
}

class Privacy extends React.Component<Props, State> {

  constructor(props: Props, state: State) {
    super(props);

    //KS: Set default state params as required
    this.state = {
      isOpen: false
    };
  }

  render() {
    return (
      <>
        <div className="App-Privacy">
          <h4>Privacy</h4>
          <p>Greening Australia Limited and its subsidiaries are committed to providing quality services to you and this policy outlines our ongoing obligations to you in respect of how we manage your personal information.</p>
          <p>We are bound by the Australian Privacy Principles (APPs) contained in the Privacy Act 1988 (Cth) (the Privacy Act). The APPs govern the way in which we collect, use, disclose, store, secure and dispose of your personal information.</p>
          <p>Personal information is information or opinions that are recorded about you where your identity is apparent, or able to be ascertained from the information provided.</p>
          <h4>1.      Why and how we collect personal information</h4>
          <p>Greening Australia collects information that is necessary for the operation of the activities and services we provide to the you and the community.</p>
          <p>Greening Australia collects personal information in relation to:</p>
          <ul>
            <li>Donors, Members, Supporters</li>
            <li>Landholders and Seed Collectors</li>
            <li>Suppliers and Customers</li>
            <li>Volunteers</li>
            <li>Students</li>
            <li>Staff</li>
            <li>Others</li>
          </ul>
          <p>Personal information is collected via a number of sources including:</p>
          <ul>
            <li>Greening Australia and subsidiary website forms and queries</li>
            <li>Email and electronic forms</li>
            <li>Telephone and hardcopy forms</li>
            <li>Surveys and research collection</li>
            <li>Database portals</li>
            <li>Recording of virtual meetings/workshops</li>
          </ul>
          <p>We will not collect personal information about you unless you choose to provide such information and when we collect Personal Information we will, where appropriate and where possible, explain to you why we are collecting the information and how we plan to use it.</p>
          <p>Where reasonable and practicable to do so, we will collect your Personal Information only from you. However, in some circumstances we may be provided with information by third parties. In such a case we will take reasonable steps to ensure that you are made aware of the information provided to us by the third party.</p>
          <h4>2.     What information we collect</h4>
          <p>The nature and type of information we collect depends on context and requirement of that information. In most instances we will collect information relating to your name, date of birth (when necessary), contact details (including address, email and telephone) and bank or credit card details when it is required for making or receiving a payment including donations and membership fees.</p>
          <p>In relation to attending a Greening Australia or subsidiary event we may also request emergency contact details and other relevant information such as in relation to food allergies.</p>
          <p><em><u>Website</u></em></p>
          <p>When using our website, information is collected on your servers address, date and time of visit, pages accessed and documents downloaded.</p>
          <h4>3.      How personal information is used and disclosed</h4>
          <p>Personal information is used for the primary purpose outlined above, or, where you have consented for your information to be used for another purpose, we may use it for that purpose.</p>
          <p>Personal information may be used for providing you with information relating to Greening Australia or its subsidiaries activities including promotional material, newsletters and donation requests. If you did not sign up for these communications and/or no longer wish to receive them, you may ‘opt-out’ at any time (see Point 8 &#8211; ‘Opt Out of Communications’ below).</p>
          <p>Although Greening Australia does not readily share or disclose your personal information with other entities, your personal information may be disclosed in a number of specific circumstances including the following:</p>
          <ul>
            <li>Third parties where you consent to the use or disclosure;</li>
            <li>To Government departments when relevant (as permitted under the APPs)</li>
            <li>Where required or authorised by</li>
          </ul>
          <p>Greening Australia may also provide personal information, or access to information, to its agents such as technology services providers, legal advisors or consultants when it is necessary for the provision of services. Any agents that have access to personal information collect by Greening Australia are required to follow this policy.</p>
          <h4>4.     Sensitive Information</h4>
          <p>Sensitive information is defined in the Privacy Act to include information or opinion about such things as an individual&#8217;s racial or ethnic origin, political opinions, membership of a political association, religious or philosophical beliefs, membership of a trade union or other professional body, criminal record or health information.</p>
          <p>Whilst Greening Australia does not currently collect sensitive information, if at any time sensitive information is collected, it will only be used by us:</p>
          <ul>
            <li>for the primary purpose for which it was obtained</li>
            <li>with your consent; or</li>
            <li>where required or authorised by</li>
          </ul>
          <h4>5.     Security of Personal Information</h4>
          <p>Your Personal Information is securely stored in a manner that reasonably protects it from misuse and loss and from unauthorised access, modification or disclosure.</p>
          <p>When your Personal Information is no longer needed for the purpose for which it was obtained, we will take reasonable steps to destroy or permanently de-identify your Personal Information. However, most of the Personal Information is or will be stored in files which will be kept by us for a minimum of 7 years. Personal information relating to Registered Training Organisation activities will be kept for a minimum of 30 years.</p>
          <h4>6.     Access to your Personal Information</h4>
          <p>You may access the Personal Information we hold about you and to update and/or correct it, subject to certain exceptions. If you wish to access your Personal Information, please contact us using the details provided under Point 9 below.</p>
          <p>In order to protect your Personal Information we may require identification from you before releasing the requested information.</p>
          <h4>7.     Maintaining the Quality of your Personal Information</h4>
          <p>It is important to us that your Personal Information is up to date. We will take reasonable steps to make sure that your Personal Information is accurate, complete and up-to-date. If you find that the information we have is not up to date or is inaccurate, please advise us as soon as practicable so we can update our records and ensure we can continue to provide quality services to you.</p>
          <h4>8.     Opt-Out of Communications</h4>
          <p>If you no longer wish to receive communications from Greening Australia, you may ‘opt out’ at any time, using any of the following methods:</p>
          <ul>
            <li>Using the unsubscribe link at the bottom of relevant emails</li>
            <li>Send an email to: <a href="mailto:communications@greeningaustralia.org.au"><span>communications@greeningaustralia.org.au</span></a></li>
            <li>Telephone 03 9450 5300</li>
          </ul>
          <p>Subscription preferences may also be updated at anytime via the website.</p>
          <h4>9.     Complaints and Enquiries</h4>
          <p>If you have any queries or complaints about our Privacy Policy please contact us at:</p>
          <p><strong>Greening Australia Limited</strong></p>
          <p><em>Company Secretary</em></p>
          <p>Level 3, 349 Collins St</p>
          <p>Melbourne VIC 3000</p>
          <p>Email: <a href="mailto:communications@greeningaustralia.org.au"><span>communications@greeningaustralia.org.au</span></a></p>
          <p>Phone: 03 9450 5323</p>
          <p><strong>Dated: 21<sup>st</sup> October 2020</strong></p>
        </div>
      </>
    );
  }
}

export default Privacy;
