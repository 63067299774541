import React from 'react';
import {Jumbotron} from 'react-bootstrap';
import { createBrowserHistory } from "history";

import LandingPageMenu from './LandingPageMenu/LandingPageMenu';

import 'bootstrap/dist/css/bootstrap.min.css'
import { useIsAuthenticated } from '@azure/msal-react';


const Landing = () => {
  return (
    <Jumbotron>
      {/* <h4>Welcome to Greening Australia & GERX</h4> */}
      <LandingPageMenu />
    </Jumbotron>
  );
}

export default Landing;
