import React, { useEffect } from 'react';

const Signout = (props: any) => {
    useEffect(() => {
        const origin = window.location.href.split('?origin=')[1] || '';
        window.location.href = process.env.REACT_APP_GERX_LOGOUT_REDIRECT_LAST_APP_URL + '/signout?origin=' + origin || '/';
    });

    return <>Please wait while we sign you out from GERX</>
};

export default Signout;