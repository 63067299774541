import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {Link} from 'react-router-dom';
import {Button, Navbar, Nav, NavItem} from 'react-bootstrap'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";

import { generateRandomString, getIsUserAuthenticated } from '../../../Services/serviceHelper';
import { selectUserProfile } from '../../../Store/Selectors/rootSelector';
import { IUserRole } from '../../../Models/Auth/IUser';
import logo from '../../../Assets/Logos/logo-inverted.svg';

import 'bootstrap/dist/css/bootstrap.min.css';
import './Menubar.scss';

interface INavbarProps {
  isAuthenticated: boolean;
  authButtonMethod: any;
  signOutButtonMethod: any;
  signUpButtonMethod: any;
  user: any;
}

interface INavbarState {
  isOpen: boolean;
}

const allowedApps = {
  landcare: false,
  landportal: false,
  adminportal: false
};


const AuthNavItem = (props: INavbarProps) => {
  const { instance } = useMsal();

  // Not authenticated, return a sign in link
  const signupUrl = process.env.REACT_APP_GERX_SIGNUP_URL || '';
  const signupUrlWithCodeChallenge = `${signupUrl}&code_challenge=${generateRandomString(50)}`;

  const userProfile = useSelector(selectUserProfile);
  const userRoles = userProfile.roles?.map((role: IUserRole) => role.roleName);

  const landcareRoles = process.env.REACT_APP_LANDCARE_ROLES?.split(',') || [];
  const landportalRoles = process.env.REACT_APP_LANDPORTAL_ROLES?.split(',') || [];
  const adminportalRoles = process.env.REACT_APP_ADMINPORTAL_ROLES?.split(',') || [];

  if (userRoles?.some((role: string) => landcareRoles.includes(role))) allowedApps.landcare = true;
  if (userRoles?.some((role: string) => landportalRoles.includes(role))) allowedApps.landportal = true;
  if (userRoles?.some((role: string) => adminportalRoles.includes(role))) allowedApps.adminportal = true;

  return (
    <>
      <AuthenticatedTemplate>
        <NavItem>
          <Button
            onClick={props.signOutButtonMethod}
            className="btn-link nav-link border-0 btn-primary"
            color="link">Sign Out</Button>
        </NavItem>
      </AuthenticatedTemplate>
      
      <UnauthenticatedTemplate>
        <NavItem>
            <Button
              onClick={() => window.location.replace(signupUrlWithCodeChallenge)}
              className="btn-link nav-link border-0 btn-success"
              color="link">Sign Up</Button>
        </NavItem>

        <NavItem>
          <Button
            onClick={() => instance.loginRedirect()}
            className="btn-link nav-link border-0"
            color="link">Sign In</Button>
        </NavItem>
      </UnauthenticatedTemplate>
    </>
  );
}

const Menubar = (props: INavbarProps) => {
  return (
      <div className="gerx-menubar">
        <Navbar.Brand as={Link} to={"/"}><img src={logo} className="App-logo" alt="logo" /></Navbar.Brand>
        <Navbar collapseOnSelect expand="lg">
            <Navbar.Toggle />
            <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
              { allowedApps.landcare ?  <a className="nav-link" href={process.env.REACT_APP_LANDCARE_BASEURI || ""}>LandTrack</a> : <></>}
              { allowedApps.landportal ?  <a className="nav-link" href={process.env.REACT_APP_LANDPORTAL_BASEURI || ""}>LandPortal</a> : <></>}
              { allowedApps.adminportal ?  <a className="nav-link" href={process.env.REACT_APP_ADMINPANEL_BASEURI || ""}>AdminPanel</a> : <></>}
              <Nav.Link as={Link} to={"/statistics"}>Statistics</Nav.Link>
              <a className="nav-link" href={process.env.REACT_APP_NEWS_BASEURI || ""} target="_blank">News</a>
              <Nav.Link as={Link} to={"/about"}>About</Nav.Link>
              <Nav.Link as={Link} to={"/contact"}>Contact</Nav.Link>
            </Nav>
            <Nav>
              <AuthNavItem 
                isAuthenticated={props.isAuthenticated}
                authButtonMethod={props.authButtonMethod}
                signUpButtonMethod={props.signUpButtonMethod}
                signOutButtonMethod={props.signOutButtonMethod}
                user={props.user}
            />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
  );
}

export default Menubar;
