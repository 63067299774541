import React, { useRef, useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Container } from 'react-bootstrap';
//import { landCareList, getUserProfile } from '../../../Store/Reducers/GerxStoreSlice';
import './Statistics.scss';
//import 'bootstrap-daterangepicker/daterangepicker.css';

import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";

const report_base_url = process.env.REACT_APP_GERX_REPORT_BASE_URL || "";
const landregbystatemap_url = process.env.REACT_APP_GERX_LAND_REGISTRATIONS_BY_STATE_CHART_URL || "";
const landregbystatetable_url = process.env.REACT_APP_GERX_LAND_REGISTRATIONS_BY_STATE_TABLE_URL || "";


const sdk = new ChartsEmbedSDK({baseUrl: report_base_url});
const chart_landregbystate_map = sdk.createChart({chartId: landregbystatemap_url});
const chart_landregbystate_table = sdk.createChart({chartId: landregbystatetable_url});

let Statistics: React.FC<any> = (props) => {
    const [token, setToken] = useState<string>()
    const dispatch = useDispatch();

    const ref_landregbystat_map = useRef(null);
    const ref_landregbystat_table = useRef(null);

    const renderChart = useCallback(async (chart, ref) => {
        try {
          await chart.render(ref);
        } catch (e) {
          console.error(e);
        }
    }, []);
    
    const setRefChart = useCallback((chart, refChart, ref) => {
        if (ref) {
          renderChart(chart, ref);
        }
        // Save a reference to the node
        refChart.current = ref;
    }, [renderChart]);

    const setRefLandRegByStateMap = (ref: any) =>  setRefChart(chart_landregbystate_map, ref_landregbystat_map, ref) ;
    const setRefLandRegByStateTable = (ref: any) =>  setRefChart(chart_landregbystate_table, ref_landregbystat_table, ref) ;

    /*useEffect(() => {
        const getListings = async () => {
          const { getTokens } = props
          const tokens: { accessToken: string, idToken: string } = await getTokens(['https://gerx.onmicrosoft.com/devapi/read', 'https://gerx.onmicrosoft.com/devapi/write']);
          await console.log('tokens: ', tokens.accessToken)
          await setToken(tokens.accessToken)
        }
        getListings()
      })*/
      
    const createReport = (colSize: number, reportId: string, reportRef: any) => {
        return (
          <Col md={colSize}>
          <div className="new-statistics">
            <div className="charts">
              <div id={reportId} ref={reportRef}></div>
            </div>
          </div>
        </Col>
        )
    }

    return(    
      <Container fluid>
        <Row>
          <br></br>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>  
            <Row>
            {/* Registrations By State Map */}
            { createReport(8, "geoChart2", setRefLandRegByStateMap) }
            {/* Registrations By State Table */}
            { createReport(3, "tableChart2", setRefLandRegByStateTable) }
            </Row>  
           </Col>
         </Row>
      </Container>
    );

}

export default Statistics;