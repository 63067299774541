import { createSlice, PayloadAction} from '@reduxjs/toolkit';
import { IUserProfile } from '../../Models/Auth/IUser';
import { fetchUserProfile } from '../API/gerxService';

import { AppThunk } from '../RootStore';

export interface IGerxStoreState {
  userProfile: IUserProfile;
  error: any;
  isLoading: boolean;
};

const initialState: IGerxStoreState = {
  userProfile: {} as IUserProfile,
  error: {},
  isLoading: false
};

const gerxStoreSlice = createSlice({
  name: "gerxStore",
  initialState,
  reducers: {
    getUserProfileSuccess(state: IGerxStoreState, {payload}: PayloadAction<IUserProfile>) {
      state.userProfile = payload;
    },
    getUserProfileFailed(state: IGerxStoreState, {payload}: PayloadAction<any>) {
      state.error = payload;
    },
    startLoading(state: IGerxStoreState) {
      state.isLoading = true;
    },
    stopLoading(state: IGerxStoreState) {
      state.isLoading = false;
    }
  }
});

export const GerxStoreReducers = gerxStoreSlice.reducer;

export const getUserProfile = (getAccessToken: Function) : AppThunk => async dispatch => {
  try {
    const accessToken = await getAccessToken();
    const result = await fetchUserProfile(accessToken);
    await dispatch(getUserProfileSuccess(result));
  }
  catch(err: any) {
    if (err.message.search('401') != -1) {
      throw(err);
      //dispatch(getUserProfileFailed(err));
    }
  }
};

export const { getUserProfileSuccess, getUserProfileFailed, startLoading, stopLoading } = gerxStoreSlice.actions;
