import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import {Jumbotron} from 'react-bootstrap';

interface Props {}

interface State {
  isOpen: boolean //KS: Can define any specific state params
}

class Contact extends React.Component<Props, State> {

  constructor(props: Props, state: State) {
    super(props);

    //KS: Set default state params as required
    this.state = {
      isOpen: false
    };
  }

  render() {
    return (
      <Jumbotron>
        <h4>Contact Page</h4>
        <p className="lead">For support please email <a href="mailto:support@gerx.org.au">support@gerx.org.au</a></p>
        <p className="lead">Please include your name, email and mobile number and a description of how we can assist you</p>
        <p></p>
        <p className="lead">Regards</p>
        <p className="lead">GERX TEAM</p>
      </Jumbotron>
    );
  }
}

export default Contact;
