import { createSelector } from 'reselect';
import { RootState } from '../RootStore';

import { IUserProfile } from '../../Models/Auth/IUser';
import { IGerxStoreState } from '../Reducers/GerxStoreSlice';

export const rootSelector = (state: RootState) => state.gerxStore;

export const selectUserProfile = createSelector(
    [rootSelector],
    (state: IGerxStoreState) => state.userProfile as IUserProfile
);

export const selectIsLoading = createSelector(
    [rootSelector],
    (state: IGerxStoreState) => state.isLoading as boolean
);