import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import Environment from './Environment/Environment';

interface Props {}

interface State {
  isOpen: boolean //KS: Can define any specific state params
}

class Header extends React.Component<Props, State> {

  constructor(props: Props, state: State) {
    super(props);

    //KS: Set default state params as required
    this.state = {
      isOpen: false
    };
  }

  render() {
    return (
        <React.Fragment>
          <Environment />
        </React.Fragment>
    );
  }
}

export default Header;
