import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css'
import {Button, Jumbotron} from 'react-bootstrap';

interface WelcomeProps {
  isAuthenticated: boolean;
  authButtonMethod: any;
  user: any;
}

interface WelcomeState {
  isOpen: boolean;
}

function WelcomeContent(props: WelcomeProps) {
  // If authenticated, greet the user
  if (props.isAuthenticated) {
    return (
      <div>
        <h4>Hi {props.user.name}!</h4>
        <p>Use the navigation bar at the top to view your user profile.</p>
        <ol>
          <li>Follow the process to submit your land information</li>
          <li>A <a href="https://www.greeningaustralia.org.au/about-us/">Greening Australia</a> staff member will be in touch as soon as possible</li>
        </ol>
      </div>
    );
  }

  // Not authenticated, present a sign in button
  return (
    <div className="welcome-text">
      <p className="lead">
      <a href="https://www.greeningaustralia.org.au/about-us/">Greening Australia</a> manages information from registered landholders through the GER-X Land Portal.
      </p>
      <p className="lead">
        Greening Australia is keen to hear from landholders/managers that are interested in land restoration to any parts of their land parcels or portfolios within Australia.
      </p>
      <p className="lead">To register your interest:</p>
      <p className="lead">        
        <ol>
          <li>Click the 'Sign Up' button in the top-right to create an account</li>
          <li>Follow the process to submit your land information</li>
          <li>A Greening Australia staff member will be in touch as soon as possible</li>
        </ol>
      </p>
    </div>
  );
}

class Home extends React.Component<WelcomeProps, WelcomeState> {
  render() {
    return (
      <Jumbotron>
        {/* <h4>Welcome to Greening Australia & GERX</h4> */}
        <WelcomeContent
          isAuthenticated={this.props.isAuthenticated}
          user={this.props.user}
          authButtonMethod={this.props.authButtonMethod} />
      </Jumbotron>
    );
  }
}

export default Home;
