import { AuthenticatedTemplate } from '@azure/msal-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { IUserRole } from '../../../Models/Auth/IUser';

import { selectUserProfile } from '../../../Store/Selectors/rootSelector';
import TileButton from '../../Common/TileButton/TileButton';

import './LandingPageMenu.scss';

enum ApplicationsEnum {
  Landcare = 1,
  Landportal,
  Adminportal
}

const allowedApps = {
  landcare: false,
  landportal: false,
  adminportal: false
};

const LandingPageMenu : React.FC = () => {
  const userProfile = useSelector(selectUserProfile);

  const onClick = (e: any) => {
    let uri: string | undefined = '';

    switch (e) {
      case ApplicationsEnum.Landcare: uri = process.env.REACT_APP_LANDCARE_BASEURI; break;
      case ApplicationsEnum.Landportal: uri = process.env.REACT_APP_LANDPORTAL_BASEURI; break;
      case ApplicationsEnum.Adminportal: uri = process.env.REACT_APP_ADMINPANEL_BASEURI; break;
    }

    if (uri) window.location.href = uri;
  };

  const userRoles = userProfile.roles?.map((role: IUserRole) => role.roleName);

  const landcareRoles = process.env.REACT_APP_LANDCARE_ROLES?.split(',') || [];
  const landportalRoles = process.env.REACT_APP_LANDPORTAL_ROLES?.split(',') || [];
  const adminportalRoles = process.env.REACT_APP_ADMINPORTAL_ROLES?.split(',') || [];

  if (userRoles?.some((role: string) => landcareRoles.includes(role))) allowedApps.landcare = true;
  if (userRoles?.some((role: string) => landportalRoles.includes(role))) allowedApps.landportal = true;
  if (userRoles?.some((role: string) => adminportalRoles.includes(role))) allowedApps.adminportal = true;

  const MenuButton = (condition: boolean, title: string, subTitle: string, value: ApplicationsEnum) => {
    return (
      <>
        { !condition ? <></> :
          <>
          <div>
          <TileButton value={value} onClick={onClick}>
            <div className="menu-button">
              <h5>{title}</h5>
            </div>
          </TileButton>
          <br></br><br></br>
          <p><span className="button-body">{subTitle}</span></p>
          </div>
          </>
        }
      </>
    );
  };
  
  return (
    <>
      {!userProfile?.roles?.length ? <></> : (
        !allowedApps.landcare && !allowedApps.landportal
          ? <p>You are not authorised to use any applications. Please contact IT.</p>
          : <>
              <p className="lead">Please click on below application(s) to access them </p>

              <div className="gerx-landing-page-menu">
                {MenuButton(allowedApps.landcare, "LandTrack", "Click here to go to LandTrack Application", ApplicationsEnum.Landcare)}
                {MenuButton(allowedApps.landportal, "LandPortal", "Click here to go to LandPortal Application", ApplicationsEnum.Landportal)}
                {MenuButton(allowedApps.adminportal, "AdminPanel", "Click here to go to AdminPanel Application", ApplicationsEnum.Adminportal)}
              </div>
          </>
        )
      }
    </>
  );
};

export default LandingPageMenu;