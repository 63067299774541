import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css'
import {Button, Alert, Breadcrumb, Card, Form} from 'react-bootstrap';

interface Props {}

interface State {
  isOpen: boolean //KS: Can define any specific state params
}

class TestRBS extends React.Component<Props, State> {

  constructor(props: Props, state: State) {
    super(props);

    //KS: Set default state params as required
    this.state = {
      isOpen: false
    };
  }

  render() {
    return (
        <div className="App-Content">
            <div>
                <Form>
                    <Form.Group controlId="formEmail">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control type="email" placeholder="test@example.com" />
                    <Form.Text className="text-muted">
                        Please enter your email above.
                    </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="formPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" />
                    <Form.Text className="text-muted">
                        Please enter your email above.
                    </Form.Text>
                    </Form.Group>
                    <Button variant="secondary" type="submit">Login</Button>
                </Form>
                <Card style={{color: "#000"}}>
                    <Card.Img src="https://picsum.photos/400/400" />
                    <Card.Body>
                    <Card.Title>Card Eample</Card.Title>
                    <Card.Text>Card Text and some stuff</Card.Text>
                    <Button variant="primary">Read More</Button>
                    </Card.Body>
                </Card>
                <Breadcrumb>
                <Breadcrumb.Item>Test</Breadcrumb.Item>
                <Breadcrumb.Item>Test1</Breadcrumb.Item>
                <Breadcrumb.Item active>Test2</Breadcrumb.Item>
                </Breadcrumb>
                <Alert variant="success">This is an alert</Alert>
                <Button>Test Button</Button>
            </div>
        </div>
    );
  }
}

export default TestRBS;
