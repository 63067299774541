import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import {Jumbotron} from 'react-bootstrap';

interface Props {}

interface State {
  isOpen: boolean //KS: Can define any specific state params
}

class About extends React.Component<Props, State> {

  constructor(props: Props, state: State) {
    super(props);

    //KS: Set default state params as required
    this.state = {
      isOpen: false
    };
  }

  render() {
    return (
      <Jumbotron>
        <div className="about-text">
        {/* <h4>About Page</h4> */}
        <p className="lead">
        <a href="https://www.greeningaustralia.org.au/about-us/">Greening Australia</a> has been working with landholders for nearly 40 years and will work with you to determine the best course of action for your property.
        </p>
        <p className="lead">
        A range of financial options are available, including receiving a carbon credit revenue share or simply receiving free planting.
        </p>
        <p className="lead">
        View our <a href={process.env.REACT_APP_GERX_LANDHOLDER_BROCHURE} target="_blank">landholder brochure</a> to find out what we can do for you.
        </p>
        </div>
        <small>Version 0.1.2</small>
      </Jumbotron>
    );
  }
}

export default About;
