import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import {AuthProvider, getApiAccessToken, msalInstance } from './Containers/Auth/MsalBrowserProvider';

import App from './Components/App/App';
import * as serviceWorker from './Services/serviceWorker';
import RootStore from './Store/RootStore';

import './index.css';
import { getIsUserAuthenticated, getUserInfo } from './Services/serviceHelper';

const isAuthenticated = getIsUserAuthenticated();

const login = () => msalInstance.loginRedirect();

const logout = () => msalInstance.logoutRedirect();

const signup = () => {};

const getAccessToken = () => getApiAccessToken(msalInstance);

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <Provider store={RootStore}>
        <App  isAuthenticated={isAuthenticated}
              user={() => getUserInfo()}
              login={login}
              logout={logout}
              signup={signup}
              getAccessToken={getAccessToken}
        />
      </Provider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
